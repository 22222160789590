/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import * as CreditsApi from './Credits/Credit';

export interface UserCreditType {
  credits: number;
  datetimeCreated: string;
  datetimeUpdated: string;
  email: string;
  id: string;
  lastFreeTopup: string;
  paidCredits: number;
  userId: string;
}

export const getUserCredit = (): UseQueryResult<UserCreditType> => {
  return useQuery({
    queryKey: ['userCredit'],
    queryFn: async () => {
      const credit = await CreditsApi.getUserCredit();
      return {
        ...credit,
        paidCredits: credit?.paidCredits ?? 0
      };
    },
    enabled: false,
  });
};

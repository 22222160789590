export enum ErrorCode {
    UNKNOWN_ERROR = 'A1',
    PROVIDER_ERROR = 'B1',
    PROMPT_NOT_FOUND = 'B2',
    RATE_LIMIT = 'C1',
    REQUEST_TIMEOUT = 'C2',
    INVALID_INPUT = 'D1',
    SERVER_MODEL_ERROR = 'D2',
    API_KEY_ERROR = 'D3',
    INSUFFICIENT_CREDITS = 'E1',
    NETWORK_ERROR = 'F1',
}
export enum PageKey {
  lessonPlan = 'lessonPlan',
  objective = 'objective',
  quizMakerSingle = 'quizMakerSingle',
  activities = 'activities',
  subjectExtention = 'subjectExtention',
  analogyContextualizer = 'analogyContextualizer',
  dailyLessonLog = 'dailyLessonLog',
  catchUpFridays = 'catchUpFridays',
  summativeTest = 'summativeTest',
  cerebroLessonPlan = 'lpCerebroTeachingGuide',
  topicExplorer = 'topicExplorer',
  energizer = 'energizer',
  elif = 'elif',
  photoBooth = 'photoBooth',
  unitPlan = 'unitPlan',
  pptMaker = 'pptMaker',
}

export enum LessonPlanProperties {
  lessonFormat = 'lpLessonFormat',
  subject = 'lpSubject',
  gradeLevel = 'lpGradeLevel',
  objective = 'lpObjective',
  language = 'lpLanguage',
  objective2 = 'lpObjective2',
  objective3 = 'lpObjective3',
  feature = 'feature',
  time = 'time',
  modality = 'modality',
}

export enum DllProperties {
  format = 'dllFormat',
  subject = 'dllSubject',
  gradeLevel = 'dllGradeLevel',
  objectives = 'dllObjectives',
  language = 'dllLanguage',
}

export enum LessonPlanV2Properties {
  lessonFormat = 'lessonFormat',
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  objective = 'objective',
  objective2 = 'objective2',
  objective3 = 'objective3',
  language = 'language',
  time = 'time',
}

export enum ObjectiveProperties {
  subject = 'objSubject',
  gradeLevel = 'objGradeLevel',
  competency = 'objCompetency',
  language = 'objLanguage',
}

export enum QuizMakerSingleProperties {
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  language = 'language',
  topic = 'topic',
  assessmentType = 'assessmentType',
  quantityOfQuestions = 'quantityOfQuestions',
  additionalInformation = 'additionalInformation',
  time = 'time',
  difficulty = 'difficulty',
  number = 'number',
}

export enum ActivitiesSingleProperties {
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  language = 'language',
  objective = 'objective',
  difficulty = 'difficulty',
  materials = 'materials',
  time = 'time',
}
export enum SubjectExtensionsProperties {
  subject = 'seSubject',
  gradeLevel = 'seGradeLevel',
  objective = 'seObjective',
  language = 'seLanguage',
}

export enum AnalogyContextualizerProperties {
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  referenceText = 'referenceText',
  language = 'language',
}

export enum CatchUpFridaysProperties {
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  quarterlyTheme = 'quarterlyTheme',
  subTheme = 'subTheme',
  sessionObjectives = 'sessionObjectives',
  language = 'language',
  feature = 'feature',
}

export enum SummativeTestProperties {
  subject = 'subject',
  gradeLevel = 'gradeLevel',
  language = 'language',
  topics = 'topics',
  format = 'format',
  assessmentTypes = 'assessmentTypes',
}

export enum CerebroLessonPlanProperties {
  courseTitle = 'courseTitle',
  gradeLevel = 'gradeLevel',
  courseTopic = 'courseTopic',
  contentStandard = 'contentStandard',
  performanceStandard = 'performanceStandard',
  learningCompetencies = 'learningCompetencies',
  language = 'language',
}

export enum TopicExplorerProperties {
  topic = 'topic',
}
export enum EnergizerProperties {
  gradeLevel = 'gradeLevel',
  topic = 'topic',
}

export enum ElifProperties {
  topic = 'topic',
}

export enum ImageGenerationProperties {
  subject = 'subject',
  topic = 'topic',
  design = 'design',
}

export enum PptMakerProperties {
  doc = 'doc',
  sections = 'sections',
}

export enum UnitPlanProperties {
  gradeLevel = 'gradeLevel',
  subject = 'subject',
  topic = 'topic',
  format = 'format',
  theme = 'theme',
  context = 'context',
  attributes = 'attributes',
  goals = 'goals',
}

import { useEffect, useContext, useRef } from 'react';

import Cookies from 'js-cookie';

import { Language } from '@lessonPlanner/enums';

import { COOKIE_NAMES } from '../constants/cookies';
import PortalContext from '../context/portal/context';
import {
  LessonPlanProperties,
  ObjectiveProperties,
  QuizMakerSingleProperties,
  SubjectExtensionsProperties,
  DllProperties,
  ActivitiesSingleProperties,
  AnalogyContextualizerProperties,
  CatchUpFridaysProperties,
  SummativeTestProperties,
  CerebroLessonPlanProperties,
} from '../enums/pageKey';
import { Portal } from '../enums/portal.enum';

import useLocalStorageItem from './useLocalStorageItem';

export const usePortalManagement = (country: string | undefined) => {
  const { selectedPortal, setSelectedPortal } = useContext(PortalContext);
  const { setItemByKey } = useLocalStorageItem();
  const previousPortal = useRef<Portal | null>(null);

  useEffect(() => {
    if (selectedPortal) return;

    const storedPortal = Cookies.get('selectedPortal') as Portal;
    if (storedPortal) {
      setSelectedPortal(storedPortal);
      return;
    }

    if (country) {
      const newPortal =
        country === 'PH' ? Portal.PHILIPPINES : Portal.INTERNATIONAL;
      setSelectedPortal(newPortal);
      Cookies.set(COOKIE_NAMES.SELECTED_PORTAL, newPortal, { expires: 365 });
    }
  }, [country, selectedPortal, setSelectedPortal]);

  // Reset language to English when portal changes
  useEffect(() => {
    if (!selectedPortal || selectedPortal === previousPortal.current) return;

    const languageKeys = [
      LessonPlanProperties.language,
      ObjectiveProperties.language,
      QuizMakerSingleProperties.language,
      SubjectExtensionsProperties.language,
      DllProperties.language,
      ActivitiesSingleProperties.language,
      AnalogyContextualizerProperties.language,
      CatchUpFridaysProperties.language,
      SummativeTestProperties.language,
      CerebroLessonPlanProperties.language,
    ];

    languageKeys.forEach(key => {
      setItemByKey(key, Language.ENGLISH);
    });

    previousPortal.current = selectedPortal;
  }, [selectedPortal, setItemByKey]);

  return { selectedPortal };
};

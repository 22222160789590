import React, { createContext, useState, useContext } from 'react';

interface GeneratePauseContextType {
  isGeneratePaused: boolean;
  disableButton: () => void;
  pausedButtonText: string;
}

const GeneratePauseContext = createContext<GeneratePauseContextType>({
  isGeneratePaused: false,
  disableButton: () => {
    /* no-op */
  },
  pausedButtonText: 'Please wait...',
});

export const GeneratePauseProvider: React.FC<{
  children: React.ReactNode;
  disableDuration?: number;
}> = ({ children, disableDuration = 5000 }) => {
  const [isGeneratePaused, setIsGeneratePaused] = useState(false);
  const pausedButtonText = 'Please wait...';

  const disableButton = () => {
    setIsGeneratePaused(true);

    setTimeout(() => {
      setIsGeneratePaused(false);
    }, disableDuration);
  };

  return (
    <GeneratePauseContext.Provider
      value={{ isGeneratePaused, disableButton, pausedButtonText }}
    >
      {children}
    </GeneratePauseContext.Provider>
  );
};

export const useGeneratePause = () => useContext(GeneratePauseContext);

export const TIME = ['5 mins', '15 mins', '30 mins', '1 hr'];

export const TIME_EXTRA = [
  '10 minutes',
  '30 minutes',
  '45 minutes',
  '1 hr',
  '1 hr 30 mins',
  '2 hrs',
  '3 hrs',
];

export const TIME_QUIZ_MAKER = [
  '5 mins',
  '10 mins',
  '20 mins',
  '30 mins',
  '40 mins',
  '50 mins',
  '1 hr',
];

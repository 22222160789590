export const GENERATE_ACTIVITY_COST = 1;
export const NO_CREDIT_STATUS_CODE = 429;
export const NO_CREDIT_MESSAGE = 'Not enough credits';
export const SITETITLE = 'Lesson Planner';
const parts: string[] = SITETITLE.split(' ').map((part: string) => part.trim());
export const FIRSTHALFTITLE = parts[0];
export const SECONDHALFTITLE = parts.slice(1).join(' ');
export const LC_PAGE_COMPETENCY_CHAR_LIMIT = 401;
export const LC_PAGE_SUBJECT_CHAR_LIMIT = 81;
export const LP_PAGE_OBJECTIVE_CHAR_LIMIT = 401;
export const LP_PAGE_REFERENCE_CHAR_LIMIT = 401;
export const LP_PAGE_SUBJECT_CHAR_LIMIT = 51;
export const CEREBRO_LP_PAGE_COURSE_TITLE_CHAR_LIMIT = 51;
export const ST_PAGE_TOPIC_CHAR_LIMIT = 100;
export const ST_PAGE_SUBTOPIC_CHAR_LIMIT = 200;
export const CLP_PAGE_LEARNING_COMP_CHAR_LIMIT = 700;
export const CLP_PAGE_SPECIFIC_ITEM_CHAR_LIMIT = 1000;
export const DLL_PAGE_OBJECTIVE_CHAR_LIMIT = 1000;
export const FREE_CREDITS_AMOUNT = 30;
export const TE_PAGE_TOPIC_CHAR_LIMIT = 100;
export const EN_PAGE_TOPIC_CHAR_LIMIT = 401;
export const ELIF_PAGE_TOPIC_CHAR_LIMIT = 401;
export const UP_PAGE_CHAR_LIMIT = 401;
export const PPT_MAKER_PAGE_CHAR_LIMIT = 10000;
export const PHOTO_BOOTH_CHAR_LIMIT = 401;

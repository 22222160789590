import { useCallback } from "react";

const useLocalStorageItem = () => {
  const getItemByKey = useCallback((itemKey: string) => {
    let item;

    try {
      item = window.localStorage.getItem(itemKey);
      if (!item) return null;

      // Check if the item is a valid JSON string
      if (/^[\[{].*[\]}]$/.test(item)) {
        const parsedItem = JSON.parse(item);
        return parsedItem;
      }

      return item;
    } catch (error) {
      console.error(`Error getting localStorage item by key: ${itemKey}, Data: ${item}, Error:`, error);
      return null;
    }
  }, []);

  const removeItemByKey = useCallback((itemKey: string) => {
    try {
      window.localStorage.removeItem(itemKey);
    } catch (error) {
      console.error("Error removing localStorage item by key:", error);
    }
  }, []);

  const setItemByKey = useCallback((itemKey: string, value: any) => {
    try {
      const isObject = typeof value === 'object';
      const valueToStore = isObject && value !== null ? JSON.stringify(value) : value;
      window.localStorage.setItem(itemKey, valueToStore);
    } catch (error) {
      console.error("Error setting localStorage item by key:", error);
    }
  }, []);

  return { setItemByKey, getItemByKey, removeItemByKey };
};

export default useLocalStorageItem;

// Correct usage example:
// const { getItemByKey, removeItemByKey, setItemByKey } = useLocalStorageItem();
// setItemByKey("anotherKey", "value"); // Correctly sets the item
// const anotherValue = getItemByKey("anotherKey"); // Correctly retrieves the item
// removeItemByKey("anotherKey"); // Correctly removes the item

export const PPT_MAKER_PROMPT_NAME = 'pptMaker';
export const PPT_MAKER_DOC_CHAR_LIMIT = 20000;

export interface ThemeColors {
  background?: string;
  text?: string;
  titleText?: string;
  contentText?: string;
  backgroundImageTitle?: string;
  backgroundImageContent?: Record<number, string>;
}

export enum ThemeType {
  WHITE = 'WHITE',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  WATER_COLOR = 'WATER_COLOR',
  STATIONERY = 'STATIONERY',
  BRUSH_STROKES = 'BRUSH_STROKES',
  SPACE = 'SPACE',
  CORNER_BENDS = 'CORNER_BENDS',
  EVERGREEN = 'EVERGREEN',
  OFFICE = 'OFFICE',
  CIRCLE_OF_LIFE = 'CIRCLE_OF_LIFE',
  WEATHER = 'WEATHER',
  LABORATORY = 'LABORATORY',
  GRADIENT = 'GRADIENT',
  ENERGY = 'ENERGY',
}

export enum FontOption {
  ARIAL = 'Arial',
  TIMES_NEW_ROMAN = 'Times New Roman',
  MONTSERRAT = 'Montserrat',
}

export const PPT_MAKER_FONT_OPTIONS = {
  [FontOption.ARIAL]: 'Arial',
  [FontOption.TIMES_NEW_ROMAN]: 'Times',
  [FontOption.MONTSERRAT]: 'Montserrat',
};

export const COLORS = {
  DARK_TEXT: '#333333',
  LIGHT_TEXT: '#FFFFFF',
} as const;

export const CUSTOM_THEMES = {
  WATER_COLOR: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/1_WaterColor/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/1_WaterColor/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/1_WaterColor/content-2.png`,
    },
  },
  STATIONERY: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/2_Stationary/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/2_Stationary/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/2_Stationary/content-2.png`,
    },
  },
  BRUSH_STROKES: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/3_BrushStrokes/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/3_BrushStrokes/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/3_BrushStrokes/content-2.png`,
    },
  },
  SPACE: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/4_Space/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/4_Space/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/4_Space/content-2.png`,
    },
  },
  CORNER_BENDS: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/8_CornerBends/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/8_CornerBends/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/8_CornerBends/content-2.png`,
    },
  },
  EVERGREEN: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/9_Evergreen/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/9_Evergreen/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/9_Evergreen/content-2.png`,
    },
  },
  OFFICE: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/10_Office/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/10_Office/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/10_Office/content-2.png`,
    },
  },
  CIRCLE_OF_LIFE: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/11_CircleOfLife/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/11_CircleOfLife/content.png`,
    },
  },
  WEATHER: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/12_Weather/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/12_Weather/content.png`,
    },
  },
  LABORATORY: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/13_Laboratory/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/13_Laboratory/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/13_Laboratory/content-2.png`,
    },
  },
  GRADIENT: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/14_Gradient/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/14_Gradient/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/14_Gradient/content-2.png`,
    },
  },
  ENERGY: {
    title: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/15_Energy/title.png`,
    content: {
      1: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/15_Energy/content-1.png`,
      2: `${process.env.NEXT_PUBLIC_GOOGLE_CDN}/images/ppt-themes/15_Energy/content-2.png`,
    },
  },
} as const;

export const PPT_MAKER_THEMES: Record<ThemeType, ThemeColors> = {
  [ThemeType.WHITE]: {
    background: '#FFFFFF',
    text: COLORS.DARK_TEXT,
  },
  [ThemeType.BLUE]: {
    background: '#38B6FF',
    text: COLORS.LIGHT_TEXT,
  },
  [ThemeType.ORANGE]: {
    background: '#FAA94A',
    text: COLORS.LIGHT_TEXT,
  },
  [ThemeType.SPACE]: {
    text: COLORS.LIGHT_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.SPACE.title,
    backgroundImageContent: CUSTOM_THEMES.SPACE.content,
  },
  [ThemeType.WATER_COLOR]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.WATER_COLOR.title,
    backgroundImageContent: CUSTOM_THEMES.WATER_COLOR.content,
  },
  [ThemeType.STATIONERY]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.STATIONERY.title,
    backgroundImageContent: CUSTOM_THEMES.STATIONERY.content,
  },
  [ThemeType.BRUSH_STROKES]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.BRUSH_STROKES.title,
    backgroundImageContent: CUSTOM_THEMES.BRUSH_STROKES.content,
  },

  [ThemeType.CORNER_BENDS]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.CORNER_BENDS.title,
    backgroundImageContent: CUSTOM_THEMES.CORNER_BENDS.content,
  },
  [ThemeType.EVERGREEN]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.EVERGREEN.title,
    backgroundImageContent: CUSTOM_THEMES.EVERGREEN.content,
  },
  [ThemeType.OFFICE]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.OFFICE.title,
    backgroundImageContent: CUSTOM_THEMES.OFFICE.content,
  },
  [ThemeType.CIRCLE_OF_LIFE]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.CIRCLE_OF_LIFE.title,
    backgroundImageContent: CUSTOM_THEMES.CIRCLE_OF_LIFE.content,
  },
  [ThemeType.WEATHER]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.WEATHER.title,
    backgroundImageContent: CUSTOM_THEMES.WEATHER.content,
  },
  [ThemeType.LABORATORY]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.LABORATORY.title,
    backgroundImageContent: CUSTOM_THEMES.LABORATORY.content,
  },
  [ThemeType.GRADIENT]: {
    text: COLORS.LIGHT_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.GRADIENT.title,
    backgroundImageContent: CUSTOM_THEMES.GRADIENT.content,
  },
  [ThemeType.ENERGY]: {
    text: COLORS.DARK_TEXT,
    backgroundImageTitle: CUSTOM_THEMES.ENERGY.title,
    backgroundImageContent: CUSTOM_THEMES.ENERGY.content,
  },
};

const BASE_THEME_CIRCLE_STYLE = 'border border-gray-200 bg-cover bg-center';
const DARK_TEXT_STYLE = 'text-gray-900';
const LIGHT_TEXT_STYLE = 'text-white';
export const THEME_CIRCLE_STYLES = {
  WHITE: `bg-white ${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  BLUE: `bg-blue-400 ${LIGHT_TEXT_STYLE}`,
  ORANGE: `bg-orange-400 ${LIGHT_TEXT_STYLE}`,
  WATER_COLOR: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  STATIONERY: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  BRUSH_STROKES: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  SPACE: `${BASE_THEME_CIRCLE_STYLE} ${LIGHT_TEXT_STYLE}`,
  CORNER_BENDS: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  EVERGREEN: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  OFFICE: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  CIRCLE_OF_LIFE: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  WEATHER: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  LABORATORY: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
  GRADIENT: `${BASE_THEME_CIRCLE_STYLE} ${LIGHT_TEXT_STYLE}`,
  ENERGY: `${BASE_THEME_CIRCLE_STYLE} ${DARK_TEXT_STYLE}`,
} as const;

export enum TextAlignment {
  LEFT = 'left',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export interface TextStyle {
  fontSize: number;
  fontWeight: FontWeight;
  alignment: TextAlignment;
}

export const TITLE_STYLE: TextStyle = {
  fontSize: 100,
  fontWeight: FontWeight.BOLD,
  alignment: TextAlignment.CENTER,
};

export const HEADER_STYLE: TextStyle = {
  fontSize: 46,
  fontWeight: FontWeight.BOLD,
  alignment: TextAlignment.LEFT,
};

export const BODY_STYLE: TextStyle = {
  fontSize: 36,
  fontWeight: FontWeight.NORMAL,
  alignment: TextAlignment.JUSTIFY,
};

export const DEFAULT_TYPOGRAPHY = {
  titleStyle: TITLE_STYLE,
  headerStyle: HEADER_STYLE,
  bodyStyle: BODY_STYLE,
  lineSpacing: 1.5,
};

export const FONTS = [
  { id: FontOption.ARIAL, name: FontOption.ARIAL },
  { id: FontOption.TIMES_NEW_ROMAN, name: FontOption.TIMES_NEW_ROMAN },
  { id: FontOption.MONTSERRAT, name: FontOption.MONTSERRAT },
];

export const THEME_OPTIONS = Object.entries(PPT_MAKER_THEMES).map(
  ([id, colors]) => ({
    id: id as ThemeType,
    name:
      id === ThemeType.WHITE
        ? 'Basic'
        : id
            .split('_')
            .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
            .join(' '),
    background: colors.background,
    text: colors.text,
    backgroundImageTitle: colors.backgroundImageTitle ?? '',
    backgroundImageContent: colors.backgroundImageContent ?? '',
  }),
);

export enum SlideType {
  TITLE = 'title',
  CONTENT = 'content',
}

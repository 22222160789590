import { ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { Portal } from '../../enums/portal.enum';

import PortalContext from './context';

interface PortalProviderProps {
  children: ReactNode;
}

export const PortalProvider = ({ children }: PortalProviderProps) => {
  const [selectedPortal, setSelectedPortal] = useState<Portal>(() => {
    const storedPortal = Cookies.get('selectedPortal') as Portal;
    return storedPortal || Portal.DEFAULT;
  });

  useEffect(() => {
    if (selectedPortal) {
      Cookies.set('selectedPortal', selectedPortal, { expires: 365 });
    }
  }, [selectedPortal]);

  return (
    <PortalContext.Provider value={{ selectedPortal, setSelectedPortal }}>
      {children}
    </PortalContext.Provider>
  );
};

export default PortalProvider;

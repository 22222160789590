import { ReactNode, useEffect, useState, useContext } from 'react';

import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { Features } from '@lessonPlanner/constants';

import { getGeolocation } from '../api/external/Geolocation';
import { COOKIE_NAMES } from '../constants/cookies';
import { publicPages } from '../constants/routes';
import PortalContext from '../context/portal/context';
import { usePortalManagement } from '../hooks/usePortalManagement';
import { handleRedirection } from '../hooks/useRedirect';

import AnnouncementBanner from './AnnouncementBanner';
import Loader from './Loader';

interface Props {
  children: ReactNode;
}

function MainLayout(props: Props) {
  const { children } = props;
  const router = useRouter();
  const [ip, setIp] = useState<string>();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [country, setCountry] = useState<string>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { setSelectedPortal } = useContext(PortalContext);

  const { isLoading, mutateAsync } = getGeolocation(async (res) => {
    const countryCode = res.country;
    Cookies.set(COOKIE_NAMES.COUNTRY, countryCode);
    setCountry(countryCode);
    handleRedirection({
      countryCode,
      isRedirecting,
      setIsRedirecting,
      setSelectedPortal,
      router,
    });
  });

  const isPublicPage = publicPages.includes(router.pathname);

  useEffect(() => {
    setIsLoaderVisible(isLoading && !isPublicPage);
  }, [isLoading, isPublicPage]);

  useEffect(() => {
    if (isPublicPage || isRedirecting) return;

    const countryCode = Cookies.get('country');
    if (countryCode) {
      handleRedirection({
        countryCode,
        isRedirecting,
        setIsRedirecting,
        setSelectedPortal,
        router,
      });
    }
  }, [router.pathname]);

  useEffect(() => {
    if (isPublicPage) return;

    const getUserIp = async () => {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setIp(data.ip);
    };
    getUserIp();
  }, [isPublicPage]);

  useEffect(() => {
    if (isPublicPage) return;

    console.log('User IP:', ip);
    if (ip) {
      mutateAsync(ip);
      // posthog.identify(ip);
    }
  }, [ip, isPublicPage]);

  usePortalManagement(country);

  return (
    <div className="relative">
      <AnnouncementBanner featureFlag={Features.ANNOUNCEMENT_BANNER} />
      {isLoaderVisible ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center">
          <Loader position="relative" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default MainLayout;

import Cookies from 'js-cookie';
import { NextRouter } from 'next/router';

import { COOKIE_NAMES } from '../constants/cookies';
import { routes } from '../constants/routes';
import { Portal } from '../enums/portal.enum';

interface RedirectionParams {
  countryCode: string;
  isRedirecting: boolean;
  setIsRedirecting: (value: boolean) => void;
  setSelectedPortal: (portal: Portal) => void;
  router: NextRouter;
}

export const handleRedirection = ({
  countryCode,
  isRedirecting,
  setIsRedirecting,
  setSelectedPortal,
  router,
}: RedirectionParams) => {
  if (isRedirecting) return;
  const hasVisited = Cookies.get(COOKIE_NAMES.HAS_VISITED);
  if (hasVisited) return;
  setIsRedirecting(true);
  Cookies.set(COOKIE_NAMES.HAS_VISITED, 'true', { expires: 365 });

  // Set portal based on country
  const newPortal =
    countryCode === 'PH' ? Portal.PHILIPPINES : Portal.INTERNATIONAL;
  setSelectedPortal(newPortal);
  Cookies.set(COOKIE_NAMES.SELECTED_PORTAL, newPortal, { expires: 365 });

  if (router.pathname === routes.PRICING) return;

  // Redirect based on country
  if (countryCode === 'PH') {
    if (router.pathname !== routes.MAIN) {
      router.push(routes.MAIN);
    }
  } else {
    if (router.pathname !== routes.UNIT_PLAN) {
      router.push(routes.UNIT_PLAN);
    }
  }
};
